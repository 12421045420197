<template>
    <div class="client-manager" v-if="this.$store.state.clientOverview.loaded">
        <div class="member-profile-sidebar">
            <div class="user-profile-header member-profile-header" >
            <!-- <div class="client-profile-info flex"> -->
                <!-- <div class=""> -->
                <div class="member-profile-photo">
                    <UploadOverlay
                        :storeGetterKey="'clientOverview/getClientProfilePhoto'"
                        :storeUpdateFileKey="'clientOverview/updateField'"
                        :storeUpdateFileField="'clientProfilePhoto'"
                        :storeUpdateFileIDKey="'clientOverview/updateClientProfileId'"
                        :storeUpdateFileIDField="'client_profile_photo_file_id'"
                        :storeFileID="$store.state.clientOverview.clientData.client_profile_photo_file_id"
                        kind="client_profile_photo"
                        :client="$store.getters['clientOverview/client']"
                        :urlToUpload="
                            `/clients/client-profile-photo?fileId=${$store.state.clientOverview.clientData.client_profile_photo_file_id}`
                        "
                        v-if="$store.state.clientOverview.loaded"
                        :alt="''"
                    />
                </div>
                <div class="client-info">
    
                    <div class="client-full-name">
                        <b>{{ $store.getters['clientOverview/clientFullName'] }}</b>
                    </div>
                    <div class="internal-id">Internal ID# {{ $store.getters['clientOverview/client'].id }} - {{ $store.getters['clientOverview/clientStatus'] }}</div>
                    
                    <div class="client-diseases">
                        <!-- <h3>Diseases: Diabetis</h3> -->
                    </div>
                    <div class="member-profile-sidebar-buttons flex">
                        <button class="secondary top-10" type="button" @click="()=>{goToPage(sideBarTabpages[0])}">Medications</button>
                        <button class="primary top-10" type="button" @click="()=>{goToPage(sideBarTabpages[1])}">Message</button>
                    </div>
                </div>
                <div class="member-profile-sidebar-section2">
                    <!-- live vitals will go here -->
                </div>
            </div>
            <div class="assigned-coordinator">
                <AssignedServiceCoordinator :primaryCounselor="primaryCounselor" :primaryCounselorProfileImage="primaryCounselorProfileImage"/>
            </div>
            <div class="auto-shared-forms">
                <h3>Forms Auto-Shared</h3>
                <div class="auto-shared-form-list" v-if="auto_portal_forms.length>0">    
                    <div v-for="(sharedForm, sharedFormIndex) in auto_portal_forms" :key="sharedFormIndex" class="auto-shared-form-item d-flex">
                        <div class="form-title">
                            {{ sharedForm.title }} 
                        </div>
                        <div>
                             <span class="eye-icon-custom material-icons-outlined">visibility</span>
                        </div>
                     </div>
                </div>
                <div v-else>
                        No forms auto-shared
                </div>
            </div>
              <!-- <div>{{ $store.getters['clientOverview/clientTags'] }}</div> -->
                    <!-- <OfficeDropdown /> -->
                <!-- <div class="client-activity">
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits attended: {{ this.$store.state.clientOverview.attendance.attended }}
                    </div>
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits missed: {{ this.$store.state.clientOverview.attendance.missed }}
                    </div>
                </div> -->
                    <!-- <img src="@/assets/px/client-profile-placeholder.png" alt="client profile photo" title="client profile photo"> -->
                <!-- </div> -->
         
         

            <!-- <div class="client-info">
                    <div>
                        <b>{{ $store.getters['clientOverview/clientFullName'] }}</b>
                    </div>
                    <div>Internal ID# {{ $store.getters['clientOverview/client'].id }}</div>
                    <div>{{ $store.getters['clientOverview/clientTags'] }}</div>
                    <OfficeDropdown />
                </div>
                <div class="client-activity">
                    <div>
                        <b>{{ $store.getters['clientOverview/clientStatus'] }}</b>
                    </div>
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits attended: {{ this.$store.state.clientOverview.attendance.attended }}
                    </div>
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits missed: {{ this.$store.state.clientOverview.attendance.missed }}
                    </div>
                </div> -->
     
      
        </div>   
        <div class="client-manager-tabs">
                <ClientManagerTabs :pages="tabPages" @action="goToPage" />
            </div>
     
        <div class="client-overview">  
    
            <div class="flex-7 left-col margin">
                <!-- chart note div -->
                <!-- <div class="overview-portal-header">
                    <button @click="addChartNote">+ Chart Note</button>
                    <button @click="sendEmail">Send Email</button>
                    <button @click="scheduleAppt">Schedule Now</button>
                    <div class="overview-client-portal nowrap">
                        <div @click="showPortalMenu = !showPortalMenu">Reset password</div>
                        <div class="overview-client-portal-menu" v-if="showPortalMenu">
                            <ul>
                                <li v-if="clientHasPortal" @click="navigateToPortal">Go to Portal</li>
                                <li v-if="clientHasPortal" @click="navigateToMobexPatientPortal">Tablet Console</li>
                                <li v-if="clientHasPortal" @click="sendPortalPasswordReset()">
                                    Send Reset Password Link
                                </li>
                                <li v-if="clientHasPortal && userIsAdmin " @click="sendPortalPasswordReset('admin_reset')">
                                    Send Reset Password Link to me
                                </li>
                                <li v-if="unlockButton" @click="resetLogin" id="the_client_overview_unlock_button">Reset Login Attempts / Unlock Account</li>
                            </ul>
                        </div>
                    </div>
                </div> -->
                <!-- timeline div -->
                <div class="overview-client-timeline">
                    <div class="overview-portal-header">
                        <div class="left-buttons-container">
                        <button v-if="showMemberApproveBtn && !clientData.activate_status" @click="approveClient"> Approve Member</button>
                        <button v-else-if="showMemberApproveBtn && clientData.activate_status" class="approved-btn" disabled>Member Approved</button>
                        <button @click="addChartNote">+ Chart Note</button>
                        <button @click="sendEmail">Send Email</button>
                        <button @click="scheduleAppt">Schedule Now</button>
                        <button @click="setReminder">Set Reminder</button>
                    </div>
                        <div class="overview-client-portal nowrap">
                            <button @click="showPortalMenu = !showPortalMenu" class="reset-password-button">Reset password <span class="material-icons-outlined">expand_more</span></button>
                            <div class="overview-client-portal-menu" v-if="showPortalMenu">
                                <ul>
                                    <li v-if="clientHasPortal" @click="navigateToPortal">Go to Portal</li>
                                    <li v-if="clientHasPortal" @click="navigateToMobexPatientPortal">Tablet Console</li>
                                    <li v-if="clientHasPortal" @click="sendPortalPasswordReset()">
                                        Send Reset Password Link
                                    </li>
                                    <li v-if="clientHasPortal && (userIsAdmin||userIsSuperAdmin) " @click="sendPortalPasswordReset('admin_reset')">
                                        Send Reset Password Link to me
                                    </li>
                                    <li v-if="clientHasPortal && (userIsSuperAdmin) " @click="ResetPin()">
                                        Reset Tablet App Pin
                                    </li>
                                    <li v-if="unlockButton" @click="resetLogin" id="the_client_overview_unlock_button">Reset Login Attempts / Unlock Account</li>
                                </ul>
                            </div>
                    </div>
                
                </div>
                    <div class="overview-client-timeline-header">
                    <h2>Member Timeline</h2>
                    <div class="overview-timeline-select-containers">
                        <div>
                            <input type="text" v-model="searchTextRaw" placeholder="Search" ref="searchInput" @blur="handleBlur" />
                        </div>
                        <Dropdown
                            name="timelineActivity"
                            id="client-timeline-activity"
                            :label="''"
                            :options="timelineActivity"
                            placeholder="Select Activity"
                            v-model="selectedTimelineActivity"
                            @change="(value) => adjustCriteria(value, 'activity')"
                        />
                        <Dropdown
                            name="timelimeOrder"
                            id="client-timeline-order"
                            :label="''"
                            :options="timelineOrder"
                            placeholder="Select Order"
                            v-model="selectedTimelineOrder"
                            @change="(value) => adjustCriteria(value, 'date_order')"
                        />
                        <span v-if="selectedTimelineOrder == 'custom'">
                            <ejs-daterangepicker
                                :placeholder="waterMark"
                                :openOnFocus="true"
                                :open="onPickerOpen"
                                @change="(args) => adjustCriteria(args, 'date_range')"
                            ></ejs-daterangepicker>
                        </span>
                    </div>
                    </div>
                    <div class="overview-timeline-chart" v-if="activities.length > 0">
                        <div
                            v-for="(activity, activityIndex) in activities"
                            :key="activityIndex"
                            class="timeline-item bottom-15 flex" 
                            
                        >
                        <!-- :class="activityIndex==activities.length-1?'':'connecting-line'" -->
                            <div class="chart-row-icon">
                                <div v-if="activity.log_type == 'appointment'" class="timeline-icon icon-appt">
                                    A
                                </div>

                                <div v-if="activity.log_type == 'chart'" class="timeline-icon icon-chart">
                                    C
                                </div>

                                <div v-if="activity.log_type == 'forms'" class="timeline-icon icon-forms">
                                    F
                                </div>
                                <div v-if="activity.log_type == 'email'" class="timeline-icon icon-email">
                                    E
                                </div>
                                <div v-if="activity.log_type == 'payment'" class="timeline-icon icon-payment">
                                    P
                                </div>
                                <div v-if="activity.log_type == 'toxicology'" class="timeline-icon icon-toxicology">
                                    T
                                </div>
                                <div v-if="activity.log_type == 'progress'" class="timeline-icon icon-progress">
                                    P
                                </div>
                                <div v-if="activity.log_type == 'activity_reminders'" class="timeline-icon icon-progress">
                                    R
                                </div>
                            </div>
                            <div class="chart-row-date weight-600">
                                <div  v-if="activity.log_type == 'appointment'" class="appt">
                                    <div class="activity-label">Appointment</div>
                                    <div class="time">{{ dayjs(activity.dayt_create).format('MM/DD/YY') }}</div>
                                </div>
                                <div v-if="activity.log_type == 'chart'" class="chart">
                                    <div class="activity-label">Chart Note</div>
                                   <div class="time"> {{ dayjs(activity.dayt_create).format('MM/DD/YY') }}</div>
                                </div>

                                <div v-if="activity.log_type == 'forms'" class="forms">
                                    <div class="activity-label">Form</div>
                                   <div class="time"> {{ dayjs(activity.dayt_create).format('MM/DD/YY') }}</div>
                                </div>
                                <div v-if="activity.log_type == 'email'" class="email">
                                    <div class="activity-label"> Email</div>
                                   <div class="time"> {{ dayjs(activity.dayt_create).format('MM/DD/YY') }}</div>
                                </div>
                                <div v-if="activity.log_type == 'payment'" class="payment">
                                    <div class="activity-label"> Payment</div>
                                   <div class="time"> {{ dayjs(activity.dayt_create).format('MM/DD/YY') }}</div>
                                </div>
                                <div v-if="activity.log_type == 'toxicology'" class="toxicology">
                                    <div class="activity-label"> Toxicology</div>
                                   <div class="time"> {{ dayjs(activity.dayt_create).format('MM/DD/YY') }} </div>
                                </div>
                                <div v-if="activity.log_type == 'progress'" class="progress">
                                  <div class="activity-label">Progress Note</div>
                                    <div class="time">  {{ dayjs(activity.dayt_create).format('MM/DD/YY') }}</div>
                                </div>
                                <div v-if="activity.log_type == 'activity_reminders'" class="activity">
                                  <div class="activity-label">Activity Reminder</div>
                                    <div class="time">  {{ dayjs(activity.created_at).format('MM/DD/YY') }}</div>
                                </div>
                            </div>
                            <div class="chart-row-details">
                                <!-- All activity types and their potential different layouts. -->
                                <!-- <div v-if="activity.log_type == 'appointment'" class="appt">
                                    <div class="weight-600">
                                        Appointment{{ getMeta('appointment', activity, 'subtitle') }}
                                    </div>
                                    <div class="desc-and-details">
                                        <div>{{ activity.description}}</div>
                                        <div v-if="activity.record_id && activity.page_id">
                                            <router-link
                                                :to="{
                                                    name: 'Calendar',
                                                    query: {
                                                        apptId: activity.record_id,
                                                        startTime: getMeta('appointment', activity, 'start_time')
                                                    }
                                                }"
                                            >
                                                Details
                                            </router-link>
                                        </div>
                                   </div>
                                </div> -->
                                <!-- New Appointment Log UI -->
                                <div v-if="activity.log_type == 'appointment'" class="appt">
                                    <div class="weight-600">
                                        {{ activity.appt_name.toUpperCase() }} - {{ activity.appt_type }}  ( {{ activity.appt_status.toUpperCase() }} )
                                    </div>
                                    <div class="desc-and-details">
                                        <div><span class="weight-600">Appointment Date: </span> {{ convertUTCToLocalDate(activity.scheduled_start_time) }}</div>
                                        <div><span class="weight-600">Appointment Time: </span> {{ convertUTCToLocalTime(activity.scheduled_start_time) }} - {{ convertUTCToLocalTime(activity.scheduled_end_time) }} {{ activities[activityIndex]['show'] }}</div>
                                        <div>
                                            <div><span class="weight-600">Participants: </span>{{ getParticipants(activity.participants) }}</div>
                                            <div><span class="weight-600">Created By: </span> - {{ activity.created_by }}</div>
                                        </div>
                                   </div>
                                </div>
                                <div v-if="activity.log_type == 'chart'" class="chart">
                                    <!-- <div class="weight-600">Chart Note</div> -->
                                    <div class="desc-and-details">
                                    <div v-html="activity.description"></div>
                                    <div v-if="activity.record_id && activity.page_id">
                                        <span class="blue pointer" @click="addChartNote($event, activity.record_id)">Details</span>
                                        <!--                                        <a></a>-->
                                        <!--                                        <router-link :to="`/${activity.page}/${activity.record_id}`"-->
                                        <!--                                            >Details-->
                                        <!--                                        </router-link>-->
                                    </div>
                                    </div>
                                </div>
                                <div v-if="activity.log_type == 'email'" class="email">
                                    <!-- <div class="weight-600">Email</div> -->
                                    <div class="desc-and-details">
                                        <div>{{ activity.description }}</div>
                                        <div v-if="activity.record_id && activity.page_id">
                                            <router-link>Details</router-link>
                                        </div>
                                   </div>
                                </div>
                                <div v-if="activity.log_type == 'payment'" class="payment">
                                    <!-- <div class="weight-600">Payment</div> -->
                                    <div class="desc-and-details">
                                        <div>{{ activity.description }}</div>
                                        <div v-if="activity.record_id && activity.page_id">
                                            <router-link :to="`/${activity.page}/${activity.record_id}`"
                                                >Details
                                            </router-link>
                                        </div>
                                  </div>
                                </div>
                                <div v-if="activity.log_type == 'toxicology'" class="toxicology">
                                    <!-- <div class="weight-600">Toxicology Report</div> -->
                                    <div class="desc-and-details">
                                        <div>{{ activity.description }}</div>
                                        <div v-if="activity.record_id && activity.page_id">
                                            <router-link :to="`/${activity.page}/${activity.record_id}`"
                                                >Details
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="activity.log_type == 'progress'" class="progress">
                                    <!-- <div class="weight-600">Progress Note</div> -->
                                <div class="desc-and-details">
                                        <div>{{ activity.description }}</div>
                                        <div
                                            v-if="
                                                activity.record_id &&
                                                    activity.page_id &&
                                                    getMeta('progress', activity, 'signed') !== 'signing' &&
                                                    getMeta('progress', activity, 'complete') !== 'complete'
                                            "
                                        >
                                            <router-link :to="`/${activity.page}/${activity.record_id}`"
                                                >Details
                                            </router-link>
                                        </div>
                                        <div
                                            v-if="
                                                activity.record_id && getMeta('progress', activity, 'signed') == 'signing'
                                            "
                                        >
                                            <a href="" @click.prevent="getFile({ id: activity.record_id })">
                                                Details
                                            </a>
                                        </div>
                                        <div
                                            v-if="
                                                activity.record_id &&
                                                    getMeta('progress', activity, 'complete') == 'complete'
                                            "
                                        >
                                            <a href="" @click.prevent="getFile({ id: activity.record_id })">
                                                Details
                                            </a>
                                        </div>
                                </div>
                                </div>
                                <div v-if="activity.log_type == 'forms'" class="forms">
                                        <div class="weight-600">{{ getMeta('forms', activity, 'subtitle') }}</div>
                                        <div class="desc-and-details">
                                        <div>{{ activity.description }}</div>
                                        <div
                                            v-if="
                                                activity.record_id &&
                                                    activity.page_id &&
                                                    getMeta('progress', activity, 'signed') !== 'signing' &&
                                                    getMeta('progress', activity, 'complete') !== 'complete'
                                            "
                                        >
                                            <router-link :to="`/${activity.page.replace('portal-', '')}/${activity.record_id}`"
                                                >Details
                                            </router-link>
                                        </div>
                                        <div v-if="activity.record_id && getMeta('forms', activity, 'signed') == 'signing'">
                                            <a href="" @click.prevent="getFile({ id: activity.record_id })">
                                                Details
                                            </a>
                                        </div>
                                        <div
                                            v-if="
                                                activity.record_id && getMeta('forms', activity, 'complete') == 'complete'
                                            "
                                        >
                                            <a href="" @click.prevent="getFile({ id: activity.record_id })">
                                                Details
                                            </a>
                                        </div>
                                </div>
                                </div>
                                <div v-if="activity.log_type == 'activity_reminders'" class="activity">
                                    <div v-if="!reminderData || (reminderData && reminderData.id !== activity.id)">
                                        <div class="weight-600">
                                            <span>{{ activity.title.toTitleCase() }}</span>
                                        </div>
                                        <div class="desc-and-details">
                                            <div>
                                                <span class="weight-600" style="color:#4CBC9A" >Reminder Date: </span> 
                                                <span>{{ activity.formattedDate }}</span>
                                            </div>
                                            <div>
                                                <span class="weight-600" style="color:#4CBC9A">Reminder Time: </span> 
                                                <span>{{ activity.formattedTime }}</span>
                                            </div>
                                            <div>
                                                <span class="weight-600" style="color:#4CBC9A">Created By: </span> 
                                                <span>{{ activity.created_by }}</span>
                                            </div>
                                            <div class="d-flex mt-2">
                                                <button class="btn btn-primary" @click="editActivityReminder(activity.id)">Edit</button>
                                                <button class="btn btn-danger" @click="showDeleteAlertPopUp(activity.id)">Delete</button>
                                            </div>

                                        </div>
                                    </div>
                                   <div class="d-grid" v-if="reminderData && reminderData.id === activity.id">
                                        <div class="mt-2">
                                            <span>Title *</span>
                                            <textarea cols="15" rows="3" v-model="reminderData.title"></textarea>
                                        </div>
                                        <div class="mt-2">
                                            <span>Reminder Date *</span>
                                            <b-form-datepicker id="example-datepicker" v-model="reminderData.reminderDate" class="mb-2"></b-form-datepicker>
                                        </div>
                                        <div class="mt-2">
                                            <span>Reminder Time *</span>
                                            <b-form-timepicker id="ex-disabled-readonly" v-model="reminderData.reminderTime"></b-form-timepicker>
                                        </div>
                                        <div class="d-flex mt-2">                  
                                          <h6 style="color: #990202" v-if="editFormValid==false">Please fill all fields !</h6>
                                        </div>
                                        <div class="d-flex mt-2">
                                            <button class="btn btn-success" @click="updateActivityReminder()">Update</button>
                                            <button class="btn btn-danger" @click="reminderData = {}">Cancel</button>
                                        </div>
                                        
                                   </div>
                                </div>
                                <!--  End of potential activity types and their layouts. -->
                                 <!-- ### Alert Warning Popup ##### -->
                
                            </div>
                        </div>
                    </div>
                    <div class="overview-timeline-chart" v-else>No member activity to date.</div>
                </div>
            </div>

                   
              
            <div class="flex-4">
                        <!-- office memo div -->
                <div class="overview-memo" v-if="clientData">
                    <div class="overview-memo-header">
                        <h3 class="margin-0">Office Memo</h3>
                        <div ><span>Not part of the member's medical record</span></div>
                            <div  class="edit-icon" @click="memoEdit = !memoEdit" >
                            <ejs-tooltip class="tooltipcontainer"
                                         content="Add"
                                         target="#memo_add"
                                         cssClass="customtooltip" 
                                         position="top">
                                <span id="memo_add" class="material-icons-outlined">add_circle</span>
                            </ejs-tooltip>
                            </div>
                            

                        <div class="delete-icon" @click="deleteMemo()">
                        <ejs-tooltip class="tooltipcontainer"
                                         content="Delete"
                                         target="#memo_delete"
                                         cssClass="customtooltip" 
                                         position="top">
                            <span id="memo_delete" class="material-icons-outlined">delete</span>
                        </ejs-tooltip>
                        </div>
                        <p v-if="!memoEdit" v-text="clientData.memo"></p>
                    </div>
                        <div class="overview-memo-text-container" v-if='memoEdit' :style="memoEdit?'z-index:9':'z-index:7'">
                            <div v-if="memoEdit" >
                                <div class="memo-text-area">
                                  <textarea  v-model="clientData.memo" placeholder="Add Memo"/>
                                </div>
                                <div  class="memo-edit-buttons">
                                <button class="primary top-10 save" type="button" @click="editMemo()" v-if="memoEdit">Save</button>
                                <button class="secondary top-10 " type="button" @click="cancelMemo()" v-if="memoEdit">Cancel</button>
                            </div>
                            </div>
                    </div>
                    
                    
                </div>
      
                <!-- appointments div -->
                <!-- // appointment:"nishant "
                    // dayt_appt_end
// : 
// "2023-02-28T15:30:00.000Z"
// dayt_appt_start
// : 
// "2023-02-28T14:30:00.000Z" -->
                <div class="overview-appointments">
                    <h3>Upcoming Appointments</h3>
                    <div v-for="(appointment, appointmentIndex) in appointments" :key="appointmentIndex">
                        <div class="overview-appt-item">
                            <div class="overview-appt-item-header">
                                 <div class="appt-date"> {{  getDayJsLLFormattedString(appointment.dayt_appt_start)}}</div> 
                                 <div class="appt-time">
                                 {{ getStartAndEndTimeString(appointment.dayt_appt_start,appointment.dayt_appt_end) }}
                                 </div>
                            </div>
                            <div class="overview-appt-name-block" :class="(appointmentIndex+1)%2===0?'base-color-appt-name-block':''">
                            <!-- {{ appointment.appointment }} -->
                                <router-link
                                 :to="{
                                  name: 'Calendar',
                                    query: { apptId: appointment.appt_id, startTime: appointment.dayt_appt_start }
                                     }"
                                     >
                            <!-- {{ formatDateWithoutHours(appointment.dayt_appt_start) }} - -->
                                      {{ appointment.appointment }}
                            <!-- {{ getStartAndEndTimeString(appointment.dayt_appt_start,appointment.dayt_appt_end) }} -->
                                 </router-link>
                            </div>
                        </div>
                        <!-- <router-link
                            :to="{
                                name: 'Calendar',
                                query: { apptId: appointment.appt_id, startTime: appointment.dayt_appt_start }
                            }"
                            >{{ formatDateWithoutHours(appointment.dayt_appt_start) }} -
                            {{ appointment.appointment }}
                            {{ getStartAndEndTimeString(appointment.dayt_appt_start,appointment.dayt_appt_end) }}
                        </router-link> -->
                    </div>
                    <div v-if="appointments.length == 0">No upcoming appointments yet.</div>
                    <div class="top-15">
                        <router-link class="text-link" :to="{ name: 'Calendar' }"
                            >View Full Calendar <span class="material-icons-outlined">arrow_right_alt</span>
                        </router-link>
                    </div>
                </div>
                <!-- billing div -->
                <!-- <div class="overview-billing">
                    <div class="flex space-between">
                        <h3>Billing Overview</h3>
                        <div class="" v-if="overviewBalance">
                            <span
                                class="font-12 right-5"
                                :class="overviewBalance.title == 'Credit' ? 'green-text' : 'red-text'"
                                >{{ overviewBalance.title }}</span
                            >
                            <span class="font-16" :class="overviewBalance.title == 'Credit' ? 'green-text' : 'red-text'"
                                ><b>{{ overviewBalance.balance }}</b></span
                            >
                        </div>
                    </div>
                    <div v-if="billings.length > 0">
                        <div v-for="(billing, billingIndex) in billings" :key="billingIndex">
                            {{ formatDateWithoutHours(billing.date) }}- {{ billing.type }}
                            {{ getCurrency(billing.payment) }}
                        </div>
                    </div>
                    <div v-else>No client transactions</div>
                    <div class="top-15">
                        <router-link
                            class="view-more text-link"
                            :to="{ name: 'ClientBilling', params: { client_id: this.record_id } }"
                            >View more or make payment <span class="material-icons-outlined">arrow_right_alt</span>
                        </router-link>
                    </div>
                </div> -->
                <!-- client files div -->
                <!-- <div class="overview-client-files">
                    <h3>Client Files</h3>
                    <div class="grid">
                        <div class="file-name-wrapper x-scroll">
                            <div v-for="(file, fileIndex) in files" :key="fileIndex">
                                <a href="" @click.prevent="getFile(file)"
                                >{{ formatDateWithoutHours(file.dayt_create) }}- {{ file.file_name }}</a
                                >
                            </div>
                        </div>
                    </div>
                    <div v-if="files.length == 0">No client files yet.</div>
                    <div class="top-15">
                        <router-link
                            :to="{
                                name: 'Documents',
                                params: {
                                    overrideFilter: {
                                        sort: {},
                                        search: {},
                                        filter: { 'files.client_id': [String(this.record_id)] },
                                        page: { num_per_page: '10', current_page: 1, page_num: 1 },
                                        column: []
                                    }
                                }
                            }"
                            class="view-more text-link"
                        >
                            View all <span class="material-icons-outlined">arrow_right_alt</span>
                        </router-link>
                    </div>
                </div> -->
                <!-- shared files client div-->
                <div class="overview-files-shared">
                    <h3>Shared Files with Member</h3>
                    <div class="flex files-shared-header">
                        <div class="item-1">       
                            File Name
                        </div>
                        <div class="item-2">        
                            <span>View</span>
                        </div>
                        <div class="item-3"> 
                            <span>Download</span>
                        </div>
                    </div>
                    <div class="files-shared-list">
                        <!-- <div class="x-scroll"> -->
                            <div v-for="(sharedFile, sharedFileIndex) in shared_files" :key="sharedFileIndex" class="list-item" >
                                <div class="item-1">
                                    <a href="" @click.prevent="getFile(sharedFile)"
                                    >
                                    {{ sharedFile.file_name }}</a
                                ></div>
                                <div class="item-2"> <a href="" @click.prevent="getFile(sharedFile)"
                                    ><span class="eye-icon-custom material-icons-outlined">visibility</span></a
                                ></div>
                                <div class="item-3"><span class="download-icon-custom material-icons-outlined">file_download</span></div>
                                
                            </div>
                        <!-- </div> -->
                    </div>
                    <div v-if="shared_files.length == 0">No shared files yet.</div>
                    <div class="top-15">
                        <router-link
                            :to="{
                                name: 'Documents',
                                params: {
                                    overrideFilter: {
                                        sort: {},
                                        search: {},
                                        filter: {
                                            'files.client_id': [String(this.record_id)],
                                            shared_to_portal: [String(1)]
                                        },
                                        page: { num_per_page: '10', current_page: 1, page_num: 1 },
                                        column: []
                                    }
                                }
                            }"
                            class="view-more text-link"
                        >
                            View all <span class="material-icons-outlined">arrow_right_alt</span>
                        </router-link>
                    </div>
                </div>
                <!-- <div class="overview-files-shared">
                    <h3>Forms Auto-Shared To Client</h3>
                    <div class="">
                        <div v-for="(sharedForm, sharedFormIndex) in auto_portal_forms" :key="sharedFormIndex">
                            {{ sharedForm.title }}
                        </div>
                    </div>
                </div> -->
            </div>
          
       
           
            <!-- @saved="getTimeline" -->
        </div>
        <NewChartNoteModal
                v-if="clientData"
                :client_id="clientData.id"
                :open="showChartNote"
                :note_id="currentChartNoteId"
                @closeModal="
                    showChartNote = false;
                    currentChartNoteId = null;
                "
            />
        <EmailModal
                v-if="clientData"
                :client_id="clientData.id"
                :client_guardian_email="clientData.guardian_email"
                :open="showEmailModal"
                @closeModal="showEmailModal = false"
            />
        <SetActivityReminderModal
                v-if="clientData"
                :client_id="$route.params.record_id"
                :client_email="clientData.email"
                :open="showActivityReminderModal"
                @closeModal="
                    showActivityReminderModal = false;
                    getTimeline();
                "
            />
            <AlertDeleteReminderModal
                        v-if="showDeleteAlert"
                        :open="showDeleteAlert"
                        @cancel="showDeleteAlert = false"
                        @handleDelete="handleDelete"
                       >
                    </AlertDeleteReminderModal>
    </div>

    <Loading v-else-if="this.$store.state.clientOverview.loaded == false" />
</template>

<script>
    import Vue from 'vue';
    import Dropdown from '@/components/general/validatedInputs/Dropdown.vue';
    import dayjs from '@/util/dayjs';
    import {clients, file, appts, logs, documents, ledger, auth, users, activityReminder} from '@/util/apiRequests';
    import { openPdf } from '@/util/pdf';
    import { openImage } from '@/util/image';
    import ClientManagerTabs from '@/components/client-manager/ClientManagerTabs.vue';
    import { timelineActivity, timelineOrder } from '@/util/options';
    import { getCurrency } from '@/util/getCurrency';
    import {
        // base64ToArrayBuffer,
        saveFile
    } from '@/util/genericUtilityFunctions';
    import UploadOverlay from '@/components/general/Upload/UploadOverlay.vue';
    import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import NewChartNoteModal from '@/components/general/modals/NewChartNoteModal';
    import SetActivityReminderModal from '@/components/general/modals/SetActivityReminder';
    import EmailModal from '@/components/general/modals/EmailModal.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import OfficeDropdown from '@/components/general/validatedInputs/OfficeDropdown';
    import { debounce } from 'lodash';
    import {ClientOverviewErrorMessages,CounselorsErrorMessages, Roles} from '@/util/globalConstants'
    import {getProfileImage} from '@/util/helpers/usersApi.helper'
    import AssignedServiceCoordinator from '@/components/client-manager/AssignedServiceCoordinator.vue'
    import AlertDeleteReminderModal from '../../components/general/modals/DeleteReminderModal.vue';
    import { sendActivityReminderNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';
    import { contactDetails } from '@/util/helpers/graphql/common.graphql.js';
    import { resetPin } from '@/util/helpers/graphql/resetPin.graphql.js';
    export default {
        name: 'ClientOverview',
        components: { Dropdown, ClientManagerTabs, UploadOverlay, NewChartNoteModal, EmailModal, Loading, OfficeDropdown, AssignedServiceCoordinator, SetActivityReminderModal , AlertDeleteReminderModal},
        props: {
            record_id: [String, Number]
        },
        data() {
            return {
                searchTextRaw: '',
                loading: true,
                getCurrency,
                criteria: { page: { num_per_page: '10000', page_num: 1 } },
                timelineActivity,
                timelineOrder,
                selectedTimelineActivity: null,
                selectedTimelineOrder: null,
                selectedTimelineOrderCustom: null,
                memoEdit: false,
                logType: null,
                showPortalMenu: false,
                showChartNote: false,
                showEmailModal: false,
                showActivityReminderModal: false,
                activities: [],
                appointments: [],
                billings: [],
                balanceData: {},
                files: [],
                shared_files: [],
                auto_portal_forms: [],
                clientData: {login_locked: false, tym_last_failed: null},
                currentChartNoteId: null,
                waterMark: 'Select a Range',
                dayjs: dayjs,
                tabPages: [
                    { label: 'Overview', name: 'ClientOverview', params: { record_id: this.$route.params.record_id },icon_label:"client-overview" },
                    {
                        label: 'Demographics',
                        name: 'ClientDemographics',
                        params: { client_id: this.$route.params.record_id }
                    },

                    // {
                    //     label: 'Insurance',
                    //     name: 'ClientInsurance',
                    //     params: { client_id: this.$route.params.record_id }
                    // },

                    // { label: 'Billing', name: 'ClientBilling', params: { client_id: this.$route.params.record_id } },
                    { label: 'Clinical', name: 'ClientClinical', params: { client_id: this.$route.params.record_id },icon_label:"client-clinical"  },
                    {
                        label: 'Documents & Forms',
                        name: 'ClientDocuments',
                        params: { client_id: this.$route.params.record_id },
                        icon_label:"docs-and-forms" 
                    },
                    {
                        label: 'Photos',
                        name: 'ClientPhotos',
                        params: { client_id: this.$route.params.record_id },
                        // icon_label:"docs-and-forms" 
                    },
                    {
                        label: 'Link sharing',
                        name: 'Link Sharing',
                        params: { client_id: this.$route.params.record_id },
                        // icon_label:"docs-and-forms" 
                    },
                    {
                        label: 'Vitals',
                        name: 'Vitals',
                        params: { client_id: this.$route.params.record_id },
                        // icon_label:"docs-and-forms" 
                    },
                ],
                sideBarTabpages:[
                {
                        label: 'Medications',
                        name: 'Medications',
                        params: { client_id: this.$route.params.record_id },
                        // icon_label:"docs-and-forms" 
                    },
                    {
                        label: 'Message',
                        name: 'Messaging',
                        params: { client_id: this.$route.params.record_id },
                        // icon_label:"docs-and-forms" 
                    },
                ],
                ClientOverviewErrorMessages,
                Roles,
                CounselorsErrorMessages,
                primaryCounselor:null,
                primaryCounselorProfileImage:null,
                reminderData: {},
                showDeleteAlert:false,
                indexTodelete:null,
                clientContactDetails: null,
                editFormValid:true
            };
        },
        computed: {
            overviewBalance() {
                if (
                    (this.balanceData?.credit || this.balanceData?.credit == 0) &&
                    (this.balanceData?.owes || this.balanceData?.owes == 0)
                ) {
                    let balance = this.balanceData.credit - this.balanceData.owes;
                    switch (Math.sign(balance)) {
                        case 1:
                            balance = { title: 'Credit', balance: getCurrency(balance) };
                            break;
                        case -1:
                            balance = { title: 'Owes', balance: getCurrency(Math.abs(balance)) };
                            break;
                        case 0:
                            balance = { title: 'Owes', balance: getCurrency(balance) };
                            break;
                        default:
                            break;
                    }
                    return balance;
                    // return getCurrency(this.balanceData.credit - this.balanceData.owes);
                    //return "";
                }
                return null;
            },
            clientId() {
                return this.$store.state.current_client_id;
                //return this.$store.state.clientData.
            },
            clientHasPortal() {
                 return true; 
                 // Boolean(this.clientData?.has_portal_acct);
            },
          unlockButton(){
            const { tym_last_failed, login_locked} = this.clientData

            let display = false

            const usr = this.$store.state.user;

            if (usr.role_id !== 1){
              display = false
            } else if (!tym_last_failed){
              display =  false
            } else if (dayjs().isAfter(dayjs(tym_last_failed).add(1,'day'))){
              display = false
            } else if (login_locked){
              display = true
            }

            return display

          },
          userIsSuperAdmin() {
                if (this.$store.state.user.role_id==Roles.SUPER_ADMIN) {
                    return true;
                } else return false;
            },
          userIsAdmin() {
                if (this.$store.state.user.role_id == this.Roles.ADMIN) {
                    return true;
                } else return false;
            },
          showMemberApproveBtn() {
                return this.userIsSuperAdmin && this.$store.state?.user?.company_id === this.$store.state?.defaultCompanyId;
            }
        },
        methods: {
            showDeleteAlertPopUp(id) {
                this.showDeleteAlert = true;
                this.indexTodelete=id;
            },
            handleBlur(e) {
                if (this.searchTextRaw || e.relatedTarget?.classList?.contains('e-focus')) {
                    this.$refs.searchInput?.focus();
                }
            },
            async getFile(f) {
                if (f.id) {
                    if (f.id) {
                        let result = await this.$api.get(file.getFile(f.id));
                        if (result.data.file.file_type == 'pdf' || result.data.file.file_type == '.pdf') {
                            await openPdf(result.data?.file?.Body, result.data?.file?.file_name);
                        } else if (result.data.file.originalContentType.includes('image')) {
                            openImage(result.data?.file?.Body);
                        } else {
                            saveFile(
                                result.data?.file?.Body,
                                result.data?.file?.originalContentType,
                                result.data?.file?.file_name
                            );
                        }
                    }
                }
            },
            getMeta(type, activity, property) {
                let string = '';
                if (type == 'forms') {
                    if (activity.metadata) {
                        try {
                            if (property == 'subtitle') {
                                let subtitle = JSON.parse(activity.metadata)?.subtitle ?? '';
                                if (subtitle) {
                                    string = subtitle;
                                }
                            }
                            if (property == 'signed') {
                                let signed = JSON.parse(activity.metadata)?.signed ?? '';
                                if (signed) {
                                    string = 'signing';
                                }
                            }
                            if (property == 'complete') {
                                let complete = JSON.parse(activity.metadata)?.complete ?? '';
                                if (complete) {
                                    string = 'complete';
                                }
                            }
                        } catch (error) {
                            //
                        }
                    }
                }
                if (type == 'appointment') {
                    if (activity.metadata) {
                        try {
                            if (property == 'subtitle') {
                                let subtitle = JSON.parse(activity.metadata)?.subtitle ?? '';
                                if (subtitle) {
                                    string = ' - ' + subtitle;
                                }
                            }
                            if (property == 'start_time') {
                                let start_time = JSON.parse(activity.metadata)?.start_time ?? '';
                                string = start_time;
                            }
                        } catch (error) {
                            //
                        }
                    }
                }
                if (type == 'progress') {
                    if (activity.metadata) {
                        try {
                            if (property == 'signed') {
                                let signed = JSON.parse(activity.metadata)?.signed ?? '';
                                if (signed) {
                                    string = 'signing';
                                }
                            }
                            if (property == 'complete') {
                                let complete = JSON.parse(activity.metadata)?.complete ?? '';
                                if (complete) {
                                    string = 'complete';
                                }
                            }
                        } catch (error) {
                            //
                        }
                    }
                }
                return string;
            },
            addChartNote(e, id) {
                this.showChartNote = true;
                this.currentChartNoteId = id;
                // this.$router.push('');
            },
            activityMetaData(activity) {
                [].includes(activity);
            },
            sendEmail() {
                this.showEmailModal = true;
                // this.$router.push('');
            },
            setReminder() { 
                this.showActivityReminderModal = true;
            },
            adjustCriteria(value, type) {
                if (type == 'search') {
                    this.criteria.search = value;
                    this.getTimeline();
                }
                if (type == 'activity') {
                    this.criteria.filter = { client_id: [String(this.record_id)] };
                    if (value == 'all') {
                        value = null;
                    }
                    this.logType = value;
                    if (value || value == null) {
                        this.getTimeline();
                    }
                } else if (type == 'date_order') {
                    if (value == 'custom') {
                        //this.criteria.date_range
                        delete this.criteria?.sort;
                    } else {
                        this.criteria.sort = { 'logs.dayt_create': value };
                        delete this.criteria?.date_range;
                        if (value) {
                            this.getTimeline();
                        }
                    }
                } else if (type == 'date_range') {
                    this.criteria.date_range = {
                        daytcol2range: 'logs.dayt_create',
                        start_date: value.value[0].toISOString(),
                        end_date: value.value[1].toISOString()
                    };

                    this.getTimeline();
                }
            },
            cancelMemo(){
                this.memoEdit = false;
                this.clientData.memo=""
            },
            editMemo() {
                try {
                    this.$api.put(clients.updateRecord(this.record_id), { client: { memo: this.clientData?.memo } });
                    // this.$api.put(clients.saveMemo(this.record_id), {memo: this.clientData?.memo})
                } catch (error) {
                    this.$toasted.error('Could not update memo');
                }
                this.memoEdit = false;
            },
            deleteMemo() {
                this.clientData.memo = '';
                try {
                    this.$api.put(clients.updateRecord(this.record_id), { client: { memo: this.clientData?.memo } });
                } catch (error) {
                    this.$toasted.error('Could not update memo');
                }
                this.memoEdit = false;
            },
            goToPage(page) {
                this.$router.push(page);
            },
            getDayJsLLFormattedString(dateString){
                return dayjs(dateString).format('LL')
            },
            getStartAndEndTimeString(appt_start_date,appt_end_date){
                const start=dayjs(appt_start_date).format('LT'),end=dayjs(appt_end_date).format('LT');
                return `${start} - ${end}`
            },
            formatDateWithoutHours(dayt) {
                return dayjs(dayt).format('MM/DD/YY');
            },
            async getClient() {
                try {
                    const res = await this.$api.get(clients.getClient(this.record_id));
                    this.clientData = res.data?.[0];
                } catch (error) {
                    this.$cl(error);
                }
            },
            async getAppointments() {
                try {
                    // const res = await this.$api.post(appts.getList(), {
                    //     criteria: {
                    //         filter: { client_id: [String(this.record_id)]},
                    //         sort: { dayt_appt_start: 'Descending' },
                    //     },
                    // });

                    const res = await this.$api.get(appts.getClientAppts(), {
                        params: {
                            u: this.record_id,
                            //Date is today at midnight
                            //  s:  dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
                            s: dayjs(new Date(new Date().setHours(0, 0, 0, 0))).format('YYYY-MM-DDTHH:mm:ssZ')
                        }
                    });

                    if (res.data?.length > 0) {
                        this.appointments = res.data;
                        this.appointments = this.appointments
                            .filter((element) => {
                                try {
                                    if (dayjs(element.dayt_appt_start).isAfter(dayjs(new Date()))) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } catch (error) {
                                    return false;
                                }
                            })
                            .sort((a, b) => (dayjs(a.dayt_appt_start).isAfter(dayjs(b.dayt_appt_start)) ? 1 : -1))
                            .slice(0, 5);
                    }
                } catch (err) {
                    this.$cl(err);
                    this.$toasted.error('Could not retrieve list of appointments');
                }
            },
            async getSharedFiles() {
                try {
                    let criteria = {
                        filter: { 'files.client_id': [String(this.record_id)], shared_to_portal: [String(1)] },
                        sort: { ['files.dayt_create']: 'Descending' }
                    };
                    const res = await this.$api.post(documents.getList(), { criteria: criteria });
                    if (res.data?.rows?.length > 0) {
                        this.shared_files = res.data.rows.slice(0,5);
                    }
                    // this.shared_files = [{dayt_create:"26/02/1998",file_name:"nishant 1.txt"},{dayt_create:"26/02/1998",file_name:"nishant file 1.txt"}];
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of shared files');
                }
            },
            async getAutoPortalForms() {
                const res = await this.$api.get(`/form_data/get-client-forms2complete/${this.record_id}`);
                if (res.data.rows) {
                    this.auto_portal_forms = [];
                    for (const row of res.data.rows) {
                        let form = {
                            component_name: row.component_name,
                            record_id: 0,
                            title: row.menu_txt
                        }
                        this.auto_portal_forms.push(form)
                    }
                    // this.auto_portal_forms=[
                        // {title:"Smith, John Polices & Consent For Treatment 10.19.22 Lorem ipsum"},
                        // {title:"Smith, John Polices & Consent For Treatment 10.19.22 Lorem ipsum"},
                        // {title:"Smith, John Polices & Consent For Treatment 10.19.22 Lorem ipsum"},
                        // {title:"Smith, John Polices & Consent For Treatment 10.19.22 Lorem ipsum"},
                        // {title:"Smith, John Polices & Consent For Treatment 10.19.22 Lorem ipsum"},
                        // {title:"Smith, John Polices & Consent For Treatment 10.19.22 Lorem ipsum"},
                        // {title:"Smith, John Polices & Consent For Treatment 10.19.22 Lorem ipsum"},
                        // {title:"Smith, John Polices & Consent For Treatment 10.19.22 Lorem ipsum"},
                    // ]
                }
            },
            async getClientFiles() {
                try {
                    let criteria = {
                        filter: { 'files.client_id': [String(this.record_id)] },
                        sort: { ['files.dayt_create']: 'Descending' }
                    };
                    const res = await this.$api.post(documents.getList(), { criteria: criteria });
                    if (res.data?.rows?.length > 0) {
                        this.files = res.data.rows.slice(0, 5);
                    }
                } catch (err) {
                    this.$cl('error');
                    //
                }
                // try {
                //     const res = await this.$api.post(clients.getClientFiles(this.record_id));

                //     this.files = res.data.files.slice(0, 5);
                // } catch (err) {
                //     this.$toasted.error('Could not retrieve list of client files');
                // }
            },
            async getTimeline() {
                try {
                    const res = await this.$api.post(logs.getActivityTimeline(), {
                        criteria: this.criteria,
                        log_type: this.logType
                    });
                    this.activities = res.data.rows.filter((e) => {
                        if (e?.log_type) {
                            return true;
                        }
                        return false;
                    });

                } catch (err) {
                    //
                }
                // try {
                //     const res = await this.$api.post(logs.getList(), {
                //         criteria: this.criteria,
                //         log_type: this.logType,
                //     });
                //     this.activities = res.data.rows;
                //     this.$cl(res.data);
                // } catch (err) {
                //     //
                // }
            },
            onPickerOpen(args) {
                args.popup.position = { X: 'left', Y: 'bottom' };
            },
            async getQuickBillingOverview() {
                let response = await this.$api.get(ledger.getTransactionQuickList(this.record_id));
                this.billings = response.data ? response.data.slice(0, 5) : [];
                let { data } = await this.$api.get(ledger.getClientBalance(this.record_id));
                this.balanceData = data;
            },
            async scheduleAppt() {
                // Checks if user is inactive. If they aren't, activeClientList is
                // updated and used to autofill client field on calendar appt.
                if (this.clientData.status.toLowerCase() === "inactive") {
                    this.$toasted.error("Cannot schedule an appointment for an inactive client.");
                } else {
                    await this.$store.dispatch('scheduler/getActiveClientList');
                    await this.$router.push({
                        name: 'Calendar',
                        params: {clientId: this.clientData.id, apptType: 'Individual' }
                    });
                }
            },
            async navigateToMobexPatientPortal() {
                let userEmail = this.$store.state.user.email;
                
                var popUpObj;
                if(process.env.VUE_APP_AWS_ENV == 'prod'){
                    popUpObj = window.open(`https://www.app.mobexhealth.com/#/pages/login?username=${userEmail}`,userEmail,"width=900," +"height=800," +"left = 250," +"top=500");
                }else if(process.env.VUE_APP_AWS_ENV == 'uat'){
                    popUpObj = window.open(`https://uat-app.mobexhealth.com/#/pages/login?username=${userEmail}`,userEmail,"width=900," +"height=800," +"left = 250," +"top=500");
                }else {
                    popUpObj = window.open(`https://dev-app.mobexhealth.com/#/pages/login?username=${userEmail}`,userEmail,"width=900," +"height=800," +"left = 250," +"top=500");
                }
                if(popUpObj && !popUpObj.closed){
                    popUpObj.focus();
                }
            },
            async navigateToPortal() {
                this.showPortalMenu = false;

                const res = await this.$api.get(clients.navigateToPortal(this.clientData.id));

                if (res.status < 200 || res.status >= 300) {
                    this.$toasted.error('Could not retrieve log in token for portal access');
                } else {
                    window.open(res.data.link);
                }
            },
            debounceSearch: debounce(function() {
                if (this.searchTextRaw) {
                    this.adjustCriteria({ like_all: this.searchTextRaw }, 'search');
                } else {
                    this.adjustCriteria({}, 'search');
                }
            }, 500),
            async sendPortalPasswordReset(type='clients') {
                const res = await this.$api.post(clients.resetPortalPassword(), {
                    clientId: this.clientData?.id,
                    type
                });
        
                if (res.status < 200 || res.status >= 300) {
                    this.$toasted.error(ClientOverviewErrorMessages.FAILED_TO_SEND_PWR);
                } else if (type==='clients'){
                    this.$toasted.success(ClientOverviewErrorMessages.SENT_PWR_CLIENT);
                } else{
                    this.$toasted.success(ClientOverviewErrorMessages.SENT_PWR_ADMIN);
                }
                this.showPortalMenu = false;
            },
            async ResetPin() {

                try {
                    const email=this.clientData?.email
                const response = await resetPin(email);
                this.$toasted.success( ClientOverviewErrorMessages.PIN_RESET);

                }
                catch( e ) {
                    
                    this.$toasted.error( ClientOverviewErrorMessages.FAILED_TO_RESET_PIN );
                }
            
            },


            async sendPortalInvite() {
                const res = await this.$api.post(clients.inviteToPortal(), {
                    clientId: this.clientData?.id
                });

                if (res.status < 200 || res.status >= 300) {
                    this.$toasted.error('Failed to send portal invite. Please try again later.');
                    this.showPortalMenu = false;
                } else {
                    this.$toasted.success('Send portal invite to client');
                    this.showPortalMenu = false;
                    await this.getClient();
                }
            },
            async resetLogin (){
                try {
                    await this.$api.patch(auth.resetClient(), {clientId: this.clientData.id});
                    this.$toasted.success('Successfully reset logins.')
                    await this.getClient()
                } catch (err) {
                    this.$toasted.error('Failed to reset logins. Please try again later.')
                }
            },
            async getClientCounselors() {
                try {
                    const counselors= await this.$api.get(clients.getCounselors(this.record_id));
                    const primaryCounselorId = counselors.data.counselors?.primary_counselor?.counselor_id
                    if(primaryCounselorId){
                        let primaryCouselorData = await this.$api.get(users.getUserDetails(primaryCounselorId));
                        const {role,firstName,lastName,profile_photo_file_id}=primaryCouselorData.data
                        this.primaryCounselor={role,firstName,lastName,}
                        this.primaryCounselorProfileImage=await getProfileImage(profile_photo_file_id)
                    }
                } catch (err) {
                    this.$toasted.error(CounselorsErrorMessages.COULDNT_RETRIEVE_CLIENT_COUNSELORS);
                }
            },
            convertUTCToLocalTime( utcDate ) {
                return new Date( utcDate ).toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute:'2-digit'
                });
            },
            convertUTCToLocalDate( utcDate ) {
                return new Date( utcDate ).toLocaleDateString();
            },
            getParticipants( participants ) {
                return (participants.map( el => {
                    if( el.provider_name ) {
                        return el.provider_name + " (Provider)";
                    }
                    else if( el.client_name ) {
                        return el.client_name + " (Client)";
                    }
                } )).join(", ");
            },
            async editActivityReminder( id ) {
                const reminderResponse = await this.$api.get( activityReminder.getReminder(id) );
                const { success, reminder } = reminderResponse.data;
                // const localDate = new Date(reminder.reminder_date).toLocaleString();
                if( success ) {
                    // const formattedDate = dayjs(localDate.split(", ")[0], 'M/D/YYYY').format('YYYY-MM-DD');
                    // const formattedTime = dayjs( localDate.split(", ")[1], 'h:mm:ss A' ).format('HH:mm');
                    this.reminderData = {
                        title: reminder.title,
                        reminderDate: reminder.formattedDate,
                        reminderTime: reminder.formattedTime,
                        id: id
                    }

                }
                else {
                    this.$toasted.error("Activity Reminder Details Not Found !");
                }
            },
            async updateActivityReminder() {

                
                if(this.reminderData.title.length==0 ||   this.reminderData.reminderTime==null || this.reminderData.reminderDate==null )
                {
                    this.editFormValid=false;
                    return;
                }
                else
                {
                    this.editFormValid=true;
                    
                }

                let reminder = {
                    title: this.reminderData.title,
                    reminderTime: this.reminderData.reminderTime,
                    reminderDate: this.reminderData.reminderDate,
                    reminderId: this.reminderData.id
                }
                const updateReminderResponse = await this.$api.put( activityReminder.updateReminder(), reminder );
                const { success, message } = updateReminderResponse.data;
                if( success ) {
                    this.$toasted.success(message);
                    this.reminderData = {};
                    this.getTimeline();
                    await this.sendActivityReminderNotification();
                }
                else {
                    this.$toasted.error(message);
                }

            },
            async handleDelete() {
                try {
                    this.showDeleteAlert = true;
                    const deleteReminderResponse = await this.$api.delete( activityReminder.deleteReminder(this.indexTodelete) );
                    const { success, message } = deleteReminderResponse.data;
                    if(success) {
                        this.$toasted.success(message);
                        this.getTimeline();
                        await this.sendActivityReminderNotification();
                    }
                } catch (e) {
                    const errorMessage = e ? e : 'Failed to delete reminder !';
                    this.$toasted.error(errorMessage);
                }
                this.showDeleteAlert = false;
            },
            async getContactDetails() {
                try {
                    const email = this.clientData.email;
                    const seniorContactDetails = await contactDetails( email );
                    return {
                        familyId: seniorContactDetails.familyId,
                        contactId: seniorContactDetails.contactId
                    }
                }
                catch( e ) {
                    this.$toasted.error( e );
                }
            },
            async sendActivityReminderNotification() {
                if( !this.clientContactDetails ) {
                    await this.getContactDetails();
                }
                const response = await sendActivityReminderNotification( this.clientContactDetails.familyId, this.clientContactDetails.contactId );
                console.log(response)
                return true;
            },
            async approveClient() {
                try {
                    let emails = [];
                    emails.push(this.clientData.email);
                    let endpoint = clients.approveClient();
                    let res = await this.$api.post(endpoint, {
                        emails: emails
                    });
                    if (res.data.success) {
                        this.clientData.activate_status = true;
                        this.$toasted.success('Member approved Successfully')
                    }
                } catch (err) {
                    this.$toasted.error('Failed to approve member')
                }
            }
        },
        async created() {
            // this.$forceUpdate();//coming from other tab, you just created a new client, so history push is being funny.

            if (this.record_id && this.record_id != 'new') {
                this.$store.commit('persistClientId', parseInt(this.record_id));
                window.localStorage.setItem('last_client_id', parseInt(this.record_id));
                this.$store.state.current_client_id = parseInt(this.record_id);
            }
            //Descending
            this.criteria.sort = { 'logs.dayt_create': 'Descending' };
            this.criteria.filter = { client_id: [String(this.record_id)] };
            // this.logType = 'email';
            await Promise.all([
                this.getAppointments(),
                this.getSharedFiles(),
                this.getAutoPortalForms(),
                this.getClientFiles(),
                this.getClient(),
                this.getTimeline(),
                this.getQuickBillingOverview(),
                this.getClientCounselors() 
            ]);
            this.clientContactDetails = await this.getContactDetails();
            this.loading = false;

            //    try {
            //         const res = await this.$api.get(appts.getClientAppts(), {
            //             params: {
            //                 u: this.clientData.id,
            //                 //Date is today at midnight
            //                 //  s:  dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            //                 s:  dayjs(new Date(new Date().setHours(0,0,0,0))).format('YYYY-MM-DDTHH:mm:ssZ'),
            //             },
            //         });
            //         let test = res.data.map((element) => {
            //             return dayjs(element.dayt_appt_start).format('MM-DD-YY');
            //             //return dayjs(element.dayt_appt_start).utc().format();
            //         });
            //         this.$cl(test);
            //    }catch(err){
            //        this.$cl(err);
            //    }
        },
        watch: {
            searchTextRaw() {
                this.debounceSearch();
            },
            showChartNote() {
                if (this.showChartNote == false) {
                    this.getTimeline();
                }
            },
            showEmailModal() {
                if (this.showEmailModal== false) {
                    this.getTimeline();
                }
            }
        }
    };
</script>

<style scoped>
    .approved-btn{
        background-color: rgb(255, 255, 255) !important;
        outline: 1px solid rgba(172, 39, 52) !important;
        color: #AC2734 !important;
        font-weight: 500;
    }
</style>